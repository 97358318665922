<template>
  <div
    class="interface-facture"
    :class="{ 'interface-facture-decalage': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('GMFTHFDD')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              no-title
              locale="fr"
              :allowed-dates="disabledStartDate"
              color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('GMFTHFDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              no-title
              locale="fr"
              :allowed-dates="disabledEndDate"
              color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- UPLOAD FILE -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <input-file-excel-num-dossier
                v-show="true"
                :value="nums"
                @searchWithNumDossier="searchWithMultipleFacture"
                :fetchFuntion="fecthFactureNumbersInExcelFile"
                :showCount="true"
                hiddenContent="true"
              />
            </div>
          </template>
          <span>Choisir un fichier</span>
        </v-tooltip>
        <v-progress-circular
          class="ml-2"
          v-if="loadingFactureMaster2"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="errorMaster2">
            <ul v-if="Array.isArray(errorMaster2)" class="mb-0">
              <li v-for="(e, index) in errorMaster2" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorMaster2 }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            v-if="checkPermission('GMFTHCS')"
            hiddenContent="true"
          />
        </div>
        <!-- ADD FACTURE -->
        <GenerateFactureMaster
          v-if="checkPermission('GAPTHGFPPM')"
          :functionGenerate="generateFactureMastersObliges"
          @RefreshData="handleFilter"
        />
        <!-- EXPORT EXCEL -->
        <export-dynamic
          v-if="checkPermission('GMFTHEXLS') || checkPermission('GMFTHEZP')"
          :filter="ComputedFilter"
          :selected="computedCheckRowsExport"
          :exportXls="exportFactureLibre"
          :exportZip="downloadZIP"
          :zipRequired="checkPermission('GMFTHEZP')"
          :xlsRequired="checkPermission('GMFTHEXLS')"
          :famille="famille"
          hiddenContent="true"
        />
        <!-- VALIDATION FACTURE  -->
        <validate-multi-facture
          v-if="
            computedCheckedRowsMultiple.length && checkPermission('GMFTHCMF')
          "
          @reloadData="reloadData"
          :dataToUse="computedCheckedRowsMultiple"
          hiddenContent="true"
          type="master"
        />
        <!-- TRANSFERT FACTURE -->
        <transfert-facture-to-libre
          v-if="
            computedCheckRowsToTransfertLibre && checkPermission('GMFTHTFATFL')
          "
          :dataToUse="computedCheckRowsToTransfertLibre"
          :indexsToRemove="computedGetIndex"
          hiddenContent="true"
          type="master"
        />
        <!-- REGLEMENT FACTURE  -->
        <reglement
          class="mr-1"
          v-if="checkPermission('GMFTHAR') && computedCheckedRowsOneClient"
          :dataToUse="computedCheckedRowsOneClientFacture"
          interfaceModal="ReglementFactureRowLibre"
          hiddenContent="true"
        />
        <!-- GENERATION AVOIR -->
        <generate-avoir
          v-if="computedCheckedRowsAvoir.length && checkPermission('GMFTHGFA')"
          @RefreshData="handleFilter"
          :selected="computedCheckedRowsAvoir"
          :functionGenerate="avoirGlobaleFactureMaster2"
          :famille="famille"
          hiddenContent="true"
          :permissionDate="checkPermission('GMFTHDCA')"
        />
        <!-- SEND EMAIL -->
        <sendMail
          v-if="
            !getLoadingMailMasterFiliale &&
              computedDataToSend.length > 0 &&
              checkPermission('GMFTHEM')
          "
          :dataToUse="computedDataToSend"
          @eventMailSendRefreshTable="handleFilter()"
          hiddenContent="true"
        />
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="
                checkPermission('GMFTHFF') ||
                  checkPermission('GMFTHFC') ||
                  checkPermission('GMFTHFS') ||
                  checkPermission('GMFTHFSC')
              "
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-sci"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GMFTHFF')"
                  v-model="isLocalStorageVendeur"
                  :items="computedGetVendeurs"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Société"
                  multiple
                  :loading="false"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GMFTHFC')"
                  v-model="isLocalStorageAcheteur"
                  :items="computedGetAcheteurs"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Client"
                  multiple
                  :loading="false"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Clients)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Clients)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- STATUTS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GMFTHFS')"
                  v-model="isLocalStorageStatut"
                  :items="computedListStatutFilter"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Statut"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- STATUT CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GMFTHFSC')"
                  v-model="isLocalStorageStatutClient"
                  :items="computedListStatutClientFilter"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Statut client"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatutClient.length - 0 }} Statuts
                      clients)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatutClient.length - 0 }} Statuts
                      clients)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-master">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="vendeur.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter ml-3">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                v-for="v in vendeur"
                :key="v.text + 'proprietaire'"
                close
                @click:close="
                  remove(v, 'vendeur', 'handleFilter', 'isLocalStorageVendeur')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="acheteur.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Client : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.value + 'proprietaire'"
                v-for="a in acheteur"
                close
                x-small
                @click:close="
                  remove(
                    a,
                    'acheteur',
                    'handleFilter',
                    'isLocalStorageAcheteur'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Statut : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.value + 'proprietaire'"
                v-for="s in statut"
                close
                @click:close="
                  remove(s, 'statut', 'handleFilter', 'isLocalStorageStatut')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut_client.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Statut client : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in statut_client"
                close
                @click:close="
                  remove(
                    s,
                    'statut_client',
                    'handleFilter',
                    'isLocalStorageStatutClient'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE FACTURE MASTER -->
      <!-- :loading="loadingFactureMaster2" -->
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="factureMaster2"
        :items-per-page="per_page"
        @item-expand="factureMaster2"
        :class="{
          'height-filter-chips-table': returnTableChips && !returnTableFiltre,
          'height-filter-table': returnTableFiltre && !returnTableChips,
          'height-filter-tous': returnTableChips && returnTableFiltre
        }"
        class="table-facture-master"
        hide-default-footer
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :calculate-widths="false"
        :fixed-header="true"
        item-key="name"
        hide-action
        :no-data-text="
          loadingFactureMaster2
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <!-- HEADERS TABLE -->
        <template v-slot:[`header.check_all`]="{ header }">
          <td>
            <span v-if="header.value != 'check_all'"> {{ header.label }}</span>
            <div v-else>
              <v-checkbox
                class="checked-global"
                v-model="checkAll"
                @change="checkAllFunction"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </div>
          </td>
        </template>
        <!-- BODY TABLE -->
        <template v-slot:[`item.check_all`]="{ item }">
          <td class="custom-cell sticky-header-1">
            <v-checkbox
              class="checked-groupe"
              v-model="item.check"
              @change="checkAllVerif(item)"
              color="#704ad1"
              :true-value="true"
              :false-value="false"
              hide-details
            ></v-checkbox>
          </td>
        </template>
        <!--  ICON EXPANDED -->
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <td class="custom-cell sticky-header-2">
            <button
              class="btn btn-anomalie"
              v-if="item && item.factures && item.factures.length > 0"
              @click.prevent.stop="showRowDetailsVueDefault(item)"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                color="#704ad1"
                title="Afficher Facture(s)"
                v-if="item.showDetails == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                color="#704ad1"
                title="Masquer Facture(s)"
                v-if="item.showDetails == true"
              />
            </button>
          </td>
        </template>
        <template v-slot:[`item.Vendeur`]="{ item }">
          <td class="custom-cell">{{ item.name ? item.name : '-' }}</td>
        </template>
        <template v-slot:[`item.Acheteur`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.sous_objet`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.num`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.numero_avoir`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.total_net_ht`]="{ item }">
          <td class="custom-cell">{{ item.total_net_ht }} €</td>
        </template>
        <template v-slot:[`item.total_tva`]="{ item }">
          <td class="custom-cell">{{ item.total_tva }} €</td></template
        >
        <template v-slot:[`item.total_ttc`]="{ item }">
          <td class="custom-cell">{{ item.total_ttc }} €</td></template
        >

        <template v-slot:[`item.etat`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.statut_client`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.reglement`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.historique`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.reste`]="{}">
          <td class="custom-cell">-</td></template
        >
        <template v-slot:[`item.date_paiement`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <!-- EXPANDED TABLE -->
        <template #expanded-item="{ headers,item }">
          <td
            class="td-expanded"
            :colspan="headers.length"
            v-if="item.showDetails"
          >
            <table :items="item.factures" class="table-expanded">
              <tbody>
                <tr
                  v-for="(data, index) in item.factures"
                  :key="data.id + 'master' + index"
                >
                  <!-- CHECK -->
                  <td
                    class="td-expanded sticky-header-1"
                    style="width : 40px !important ; min-width : 40px !important"
                  >
                    <v-checkbox
                      class="checked-expanded"
                      v-model="data.check"
                      color="#704ad1"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <!-- VIDE -->
                  <td
                    class="td-expanded sticky-header-2"
                    style="width : 40px !important ; min-width : 40px !important"
                  >
                    <span> </span>
                  </td>
                  <!-- SOCIETE -->
                  <td class="td-expanded" v-if="checkPermission('GMFTHCSO')">
                    {{
                      data.vendeur && data.vendeur.nom_compagnie
                        ? data.vendeur.nom_compagnie
                        : data.vendeur
                    }}
                  </td>
                  <!-- CLIENT -->
                  <td class="td-expanded" v-if="checkPermission('GMFTHCCL')">
                    {{ data.acheteur }}
                  </td>
                  <!-- DEPOT -->
                  <td class="td-expanded" v-if="checkPermission('GMFTHCDEPT')">
                    {{ data.sous_objet != null ? data.sous_objet : '-' }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCNF')">
                    <div class="flex-div">
                      {{ data.num }}
                      <VisualisationFacture
                        :awesome="true"
                        :facture="data.id"
                        :permission="checkPermission('GMFTHEVF')"
                      ></VisualisationFacture>
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCNFA')">
                    <div class="flex-div">
                      <span
                        v-if="
                          data.FactureAvoir.length && data.type == 'Facture'
                        "
                        class="mr-2"
                        >{{
                          getSumAvoirFacturePartial(data.FactureAvoir)
                            ? getSumAvoirFacturePartial(data.FactureAvoir)
                            : '-'
                        }}
                        €</span
                      >
                      <AvoirFacture
                        v-if="
                          data.type_facture != 'Facture proforma' &&
                            data.etat != 'Annulée' &&
                            data.type == 'Facture' &&
                            data.client_id == 20
                        "
                        :disabledQP="true"
                        :item="data"
                        :permission="checkPermission('GMFTHGDAP')"
                        :index="
                          data.vendeur && data.vendeur.nom_compagnie
                            ? data.vendeur.nom_compagnie
                            : data.vendeur + index
                        "
                        :gettersData="getProduitsFactureAvoir"
                        :methodProduct="getProductsAvoirFacture"
                        :functionCreate="createAvoirFacture"
                        :permissionDate="checkPermission('GMFTHDCA')"
                      ></AvoirFacture>
                      <PopoverAvoirFacture
                        v-if="
                          data.FactureAvoir &&
                            data.FactureAvoir.length != 0 &&
                            data.type == 'Facture'
                        "
                        :factureAvoir="data.FactureAvoir"
                        :permissionTelecharger="checkPermission('GMFTHEFAP')"
                        :permissionVoirFacture="checkPermission('GMFTHVFA')"
                        type="master"
                      >
                      </PopoverAvoirFacture>
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCDF')">
                    {{ data.date_creation | formateDate }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCMOHT')">
                    <EditableInput
                      :editable="
                        data.type == 'Facture proforma' &&
                          checkPermission('GMFTHMMF') &&
                          data.modifiable == true &&
                          false
                      "
                      champName="total_net_ht"
                      :item="data"
                      :value="data.total_net_ht"
                      type="text"
                      editableType="input"
                      :updateFunction="updateMontantHtFactureMaster2"
                      :defaultColor="true"
                      :uniteMontant="euro"
                      widthInput="110px"
                    />
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCTVA')">
                    {{ data.total_tva }} €
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCMO')">
                    <TemplateFacture
                      :permissionDownloadFacture="checkPermission('GMFTHEFP')"
                      :permissionDownloadFactureAvoir="
                        checkPermission('GMFTHEFAP')
                      "
                      :permissionValidationFacture="checkPermission('GMFTHVF')"
                      :famille="'master'"
                      :getTemplateMethod="getTemplateFactureMaster2"
                      :getterTemplate="templateFactureMaster2"
                      :validateMethod="validateFactureTemplateMaster2"
                      :item="data"
                      :project="data"
                      :componentName="$options.name"
                      :editable="
                        data.etat == 'Payé' ||
                        data.etat == 'Annulée' ||
                        data.modifiable == false
                          ? false
                          : checkPermission('GMFTHVFM')
                          ? true
                          : false
                      "
                      :download="data.etat == 'Annulée' ? false : data.id"
                    />
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCRAP')">
                    {{ data.reste }} €
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCCSF')">
                    <EditableInput
                      :item="data"
                      :value="data.etat"
                      widthInput="110px"
                      :editable="
                        checkPermission('GMFTHMSPF') &&
                          item.etat != 'Payé' &&
                          item.etat != 'Annulée' &&
                          item.modifiable == true &&
                          item.type == 'Facture' &&
                          false
                      "
                      champName="etat"
                      editableType="select"
                      :optionsSelect="computedListStatut"
                      :updateFunction="updateStatutFactureMaster2"
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCSPI')">
                    <div
                      v-if="
                        (data.type == 'facture' || data.type == 'Facture') &&
                          data.etat != 'Annulée' &&
                          data.etat != 'Payé' &&
                          data.statut_client != 'Payé' &&
                          checkPermission('GMFTHMSIPF') &&
                          data.modifiable == true &&
                          false
                      "
                    >
                      <EditableInput
                        :item="data"
                        :value="data.statut_client"
                        label="value"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"
                        widthInput="110px"
                        champName="statut_client"
                        editableType="select"
                        :optionsSelect="computedListStatutClient"
                        :updateFunction="updateStatutClientFactureMaster2"
                      />
                    </div>
                    <div v-else class="text-gras">
                      {{ data.statut_client ? data.statut_client : '-' }}
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCDP')">
                    {{ data.date_paiement ? data.date_paiement : '-' }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCRG')">
                    <div class="flex-div">
                      {{ data.montant_regle }} €
                      <PopoverReglementFacture
                        v-if="data.reglements.length"
                        :factureReglement="data.reglements"
                        :permissionModifier="checkPermission('GMFTHUR')"
                        dataInterface="master"
                      >
                      </PopoverReglementFacture>
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('GMFTHCH')">
                    <Historique
                      :awesome="true"
                      :index="data.id"
                      :permission="checkPermission('GMFTHCH')"
                      :dataToUse="data.historique"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div
        v-if="
          computedTotal &&
            (computedTotal.net_ht > 0 ||
              computedTotal.ttc > 0 ||
              computedTotal.someApaye > 0) &&
            checkPermission('GFMTHABT')
        "
        class="style-somme-th-ttc pl-2"
      >
        <div>
          <span class="bold-text"> Total HT:</span>
          {{ '   ' }}
          {{   computedTotal.net_ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
          € {{ '   ' }}
          <span class="bold-text">Total TTC:</span>
          {{ '   ' }}
          {{
            computedTotal.ttc
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          € {{ '   ' }}
          <span class="bold-text"> Total à payer : </span>
          {{ '   ' }}
          {{
            computedTotal.someApaye
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          €
        </div>
      </div>
      <div
        class="text-center pagination-ta
      ble"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import EditableInput from '../../../component/EditableInput.vue';
import ExportDynamic from '@/views/ThProject/frais/fraisDynamic/component/exportDynamic.vue';
// import filterComponent from '../component/filterComponent.vue';
// import ValidateMultipleFactureMaster2 from './component/ValidateMultipleFactureMasterFiliale2.vue';
// import ValidationMultipleStatutClient from './component/ValidationMultipleStatutClient.vue';

export default {
  name: 'Gestion-Facture-Master-2',
  data() {
    return {
      expanded: [],
      singleExpand: false,
      selectedTable: [],
      menu: false,
      factureToShow: null,
      per_page: 20,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      triggersPop: 'hover',
      date_debut: null,
      date_fin: null,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      famille: 'master',
      searchValue: null,
      checkAll: false,
      column: 'id',
      order: 'DESC',
      vendeur: [],
      acheteur: [],
      statut: [
        { value: 'A payer', text: 'A payer' },
        {
          value: 'Créé',
          text: 'Créé'
        },
        { value: 'Payé en partie', text: 'Payé en partie' }
      ],
      statut_client: [],
      euro: ' €',
      nums: null,
      isLocalStorageVendeur: [],
      isLocalStorageStatut: [],
      isLocalStorageAcheteur: [],
      isLocalStorageStatutClient: []
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingFactureMaster2',
      'getShowNotifExportFile',
      'vendeurMaster2',
      'acheteurMaster2',
      'factureMaster2',
      'checkPermission',
      'errorMaster2',
      'getLoadingMail',
      'isResponsableFiliale',
      'TotalRowsMaster2',
      'getLoadingMailMasterFiliale',
      'formateDateGetters',
      'getUserData',
      'getSumAvoirFacturePartial',
      'getProduitsFactureAvoir',
      'templateFactureMaster2',
      'computedRouterLinkFacture',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.acheteur,
        this.statut,
        this.statut_client,
        this.vendeur
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        this.vendeur.length ||
        this.acheteur.length ||
        this.statut.length ||
        this.statut_client.length
      ) {
        return true;
      }
      return false;
    },
    totalPages() {
      if (this.TotalRowsMaster2) {
        return Math.ceil(this.TotalRowsMaster2 / this.per_page);
      }
      return this.TotalRowsMaster2;
    },
    computedPopoverTriggers() {
      return this.triggersPop;
    },
    stylePopover() {
      if (this.computedPopoverTriggers == 'click') {
        return 'displayed-none';
      }
      return '';
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);

      if (this.factureMaster2.length) {
        this.factureMaster2.forEach(element => {
          element.factures.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
        return tableReglement.length == tableReglementCheck.length &&
          tableReglement.length > 0
          ? true
          : false;
      }
      return false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];
      if (this.factureMaster2.length) {
        let filiale_list = this.getUserData.filiales.map(item => item.id);

        this.factureMaster2.forEach(element => {
          element.factures.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id)
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (facture.client_id == tableReglement[0].client_id) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
      }
      return tableReglement;
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.statut.length > 0 ||
        this.statut_client.length > 0
      ) {
        return true;
      }
      return false;
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 257 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 206 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id === null
          ) {
            someHt =
              someHt +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].net_ht +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            someTtc =
              someTtc +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].ttc +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            if (
              this.computedCheckedRows[i].factures[j].type == 'Facture' &&
              (this.computedCheckedRows[i].factures[j].etat == 'A payer' ||
                this.computedCheckedRows[i].factures[j].etat ==
                  'Payé en partie' ||
                this.computedCheckedRows[i].factures[j].etat == 'Payé')
            ) {
              someApaye =
                someApaye +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].factures[j].reste +
                        '' +
                        ' '.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return {
        net_ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    },
    computedGetVendeurs() {
      let vendeurNames = this.vendeurMaster2.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedGetAcheteurs() {
      let acheteurNames = this.acheteurMaster2.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedCheckRowsExport() {
      let selected = [];
      this.factureMaster2.forEach(element => {
        element.factures.forEach(facture => {
          if (facture.check == true) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedGetIndex() {
      let arrayOfIndex = [];
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id !== null
          ) {
            arrayOfIndex.push({
              indexOfChild: this.computedCheckedRows[i].factures[j].id,
              indexOfFather: this.computedCheckedRows[i].name
            });
          }
        }
      }
      return arrayOfIndex;
    },
    computedCheckRowsToTransfertLibre() {
      let selected = [];
      this.factureMaster2.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.avoir_id !== null &&
            facture.etat == 'Annulée' &&
            facture.modifiable == true
          ) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedDataToSend() {
      let selected = [];
      this.factureMaster2.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.etat != 'Créé' &&
            facture.modifiable == true &&
            facture.type != 'Facture proforma'
          ) {
            selected.push(facture);
          }
        });
      });
      return selected;
    },
    computedCheckedRows() {
      let tableF = this.factureMaster2?.filter(f => {
        return f.factures.some(e => e.check == true);
      });
      return tableF;
    },
    computedCheckedRowsMultiple() {
      let tableF = this.factureMaster2?.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture proforma' &&
            e.modifiable == true
        );
      });
      return tableF;
    },
    computedCheckedRowsAvoir() {
      let tableF = this.factureMaster2.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture' &&
            e.avoir_id == null &&
            e.etat != 'Annulée' &&
            e.modifiable == true &&
            e.FactureAvoir.length == 0
        );
      });
      return tableF;
    },
    ComputedFilter() {
      return {
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        acheteur: this.acheteur,
        vendeur: this.vendeur,
        etat: this.statut,
        statut_client: this.statut_client,
        col: 'id'
      };
    },
    computedCheckRows() {
      let selected = [];
      this.factureMaster2?.map(element => {
        if (element.check == true) {
          selected.push(element.id);
        }
      });
      return selected;
    },
    computedListStatut() {
      return [
        { value: 'A payer', text: 'Facture à payer' },
        { value: 'Payé', text: 'Facture payée' }
      ];
    },
    computedListStatutFilter() {
      return [
        { value: 'A payer', text: 'Facture à payer' },
        { value: 'Payé', text: 'Facture payée' },
        { value: 'Crée', text: 'Facture crée' },
        { value: 'Annulée', text: 'Facture Annulée' },
        { value: 'Payé en partie', text: 'Payé en partie' }
      ];
    },
    computedListStatutClientFilter() {
      return [
        { value: 'A payer', text: 'Facture à payer' },
        { value: 'Payé', text: 'Facture payée' },
        { value: 'Crée', text: 'Facture crée' },
        { value: 'Annulée', text: 'Facture Annulée' },
        { value: 'Payé en partie', text: 'Payé en partie' }
      ];
    },
    computedListStatutClient() {
      return [
        { value: 'A payer', text: 'à payer' },
        { value: 'Payé', text: 'Payée' }
      ];
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-2'
        },
        {
          value: 'Vendeur',
          text: 'Societé',
          show: this.checkPermission('GMFTHCSO'),
          sortable: false,
          class: 'societe-th'
        },
        {
          value: 'Acheteur',
          text: 'Client',
          show: this.checkPermission('GMFTHCCL'),
          sortable: false
        },

        {
          value: 'sous_objet',
          text: 'Dépôt',
          show: this.checkPermission('GMFTHCDEPT'),
          sortable: false
        },
        {
          value: 'num',
          text: 'N° Facture',
          show: this.checkPermission('GMFTHCNF'),
          sortable: false
        },
        {
          value: 'numero_avoir',
          text: 'N° Facture Avoir',
          show: this.checkPermission('GMFTHCNFA'),
          sortable: false
        },
        {
          value: 'date',
          text: 'Date',
          show: this.checkPermission('GMFTHCDF'),
          sortable: false
        },
        {
          value: 'total_net_ht',
          text: 'Montant HT',
          show: this.checkPermission('GMFTHCMOHT'),
          sortable: false
        },
        {
          value: 'total_tva',
          text: 'TVA',
          show: this.checkPermission('GMFTHCTVA'),
          sortable: false
        },
        {
          value: 'total_ttc',
          text: 'Montant TTC',
          show: this.checkPermission('GMFTHCMO'),
          sortable: false
        },
        {
          value: 'reste',
          text: 'Reste à payer',
          show: this.checkPermission('GMFTHCRAP'),
          sortable: false
        },
        {
          value: 'etat',
          text: 'Statut',
          show: this.checkPermission('GMFTHCCSF'),
          sortable: false
        },

        {
          value: 'statut_client',
          text: 'Statut client',
          show: this.checkPermission('GMFTHCSPI'),
          sortable: false
        },
        {
          value: 'date_paiement',
          text: 'Date du paiement',
          show: this.checkPermission('GMFTHCDP'),
          sortable: false
        },
        {
          value: 'reglement',
          text: 'Reglement',
          show: this.checkPermission('GMFTHCRG'),
          sortable: false
        },
        {
          value: 'historique',
          text: 'Historique',
          show: this.checkPermission('GMFTHCH'),
          sortable: false
        }
      ];
      return fields.filter(item => item.show === true);
    }
  },
  methods: {
    ...mapActions([
      'getSousObjets',
      'getVendeurAcheteurMaster2',
      'getFacturesMaster2',
      'updateStatutFactureMaster2',
      'deleteFactureComptaRhActions',
      'updateStatutClientFactureMaster2',
      'generateAvoir',
      'exportFactureLibre',
      'downloadZIP',
      'downloadPDF',
      'displayFact',
      'updateMontantHtFactureMaster2',
      'exportPdfFactureAvoirDynamic',
      'generateFactureMastersObliges',
      'getSettingFilialeTh',
      'avoirGlobaleFactureMaster2',
      'fecthFactureNumbersInExcelFile',
      'createAvoirFacture',
      'getProductsAvoirFacture',
      'validateFactureTemplateMaster2',
      'getTemplateFactureMaster2'
    ]),
    showRowDetailsVueDefault(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      } else {
        const index = this.expanded.findIndex(
          expandedItem => expandedItem === item
        );
        if (index !== -1) {
          this.expanded.splice(index, 1);
        }
      }
    },
    disabledStartDate(date) {
      // if (this.date_fin) {
      //   return (
      //     this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
      //   );
      // }
      // return true;
      return true;
    },
    disabledEndDate(date) {
      // if (this.date_debut) {
      //   return (
      //     this.date_debut &&
      //     moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
      //   );
      // }
      // return true;
      return true;
    },
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      this.vendeur = [];
      this.acheteur = [];
      this.statut = [];
      this.statut_client = [];
      //POPOVER FILTER
      this.isLocalStorageVendeur = [];
      this.isLocalStorageAcheteur = [];
      this.isLocalStorageStatut = [];
      this.isLocalStorageStatutClient = [];
      this.handleFilter();
    },
    validerPopover() {
      //
      //SET FILTER FROM POPOVER
      //
      this.vendeur = this.isLocalStorageVendeur;
      this.acheteur = this.isLocalStorageAcheteur;
      this.statut = this.isLocalStorageStatut;
      this.statut_client = this.isLocalStorageStatutClient;
      //
      //END SET FILTER FROM POPOVER
      //
      this.handleFilter();
      this.menu = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('-');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    handleResetDate(name) {
      this[name] = null;
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.handleFilter();
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    handleClickDownloadFactureAvoir(id) {
      this.exportPdfFactureAvoirDynamic({ id: id });
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    checkAllFunction() {
      this.factureMaster2.map(item => {
        item.check = this.checkAll;
        item.factures.map(facture => {
          facture.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(facture => {
        facture.check = item.check;
      });
    },
    handleAvoirFacture(id) {},
    async handleShowFacture(id) {
      this.$refs['visualisationFactureModal'].show();
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    downloadFacture(id) {
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', id);
      this.downloadPDF(bodyFormData);
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleFilter() {
      this.checkAll = false;
      this.setLocalStorageFactureMaster2();
      this.$nextTick(async () => {
        this.getFacturesMaster2({
          page: this.page,
          per_page: this.per_page,
          famille: this.famille,
          order: 'DESC',
          column: 'id',
          grouped: true,
          vendeur_id: this.vendeur,
          acheteur_id: this.acheteur,
          etat: this.statut,
          statut_client: this.statut_client,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          search: this.searchValue,
          nums: this.nums
        });
      });
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageFactureMaster2();
    },
    uploadAvoirSelected() {
      this.$confirm(
        '',
        'Êtes-vous sûr de vouloir générer des avoirs pour ces factures (avec statut annulé) ? ',
        'warning'
      )
        .then(async () => {
          let data = this.factureMaster2
            .filter(function(item) {
              return item.check == true;
            })
            .map(function(item) {
              return item.id;
            });

          const response = await this.generateAvoir(data);
        })
        .catch(error => {});
    },
    handleDeleteModal(item) {
      this.$confirm(
        '',
        'Êtes-vous sur de vouloir supprimer cette facture ?',
        'warning'
      ).then(async () => {
        const response = await this.deleteFactureComptaRhActions(item.id);
        if (response.succes) {
          this.$alert('', 'Facture supprimer', 'success');
        } else {
          this.$alert('', response.error, 'error');
        }
      });
    },

    setLocalStorageFactureMaster2() {
      localStorage.setItem(
        'frais-th-facture-master-2',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          acheteur: this.acheteur,
          vendeur: this.vendeur,
          statut: this.statut,
          statut_client: this.statut_client,
          searchValue: this.searchValue
        })
      );
    },
    hideModal(ref) {
      this.factureToShow = null;
      this.$refs[ref].hide();
    }
  },
  components: {
    TemplateFacture: () =>
      import('@/views/component/Facture/TemplateFacture.vue'),
    TransfertFactureToLibre: () =>
      import('@/views/component/Facture/TransfertFactureToLibre.vue'),
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    VisualisationFacture: () =>
      import('@/views/component/Facture/VisualisationFacture.vue'),
    AvoirFacture: () => import('@/views/component/Facture/AvoirFacture.vue'),
    PopoverAvoirFacture: () =>
      import('@/views/component/Facture/PopoverAvoirFacture.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue'),
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    ExportDynamic,
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    GenerateFactureMaster: () =>
      import('@/views/ThProject/oblige/component/GenerateFactureMaster.vue'),
    GenerateAvoir: () => import('@/views/component/avoir/GenerateAvoir.vue'),
    InputFileExcelNumDossier: () =>
      import(
        '@/views/ThProject/componentRapportTh/inputFileExcelNumDossier.vue'
      )
  },
  async mounted() {
    this.date_fin = moment(new Date()).format('DD-MM-YYYY');
    this.date_debut = moment()
      .subtract(2, 'months')
      .format('DD-MM-YYYY');

    this.getVendeurAcheteurMaster2();
    if (localStorage.getItem('frais-th-facture-master-2')) {
      var localStor = JSON.parse(
        localStorage.getItem('frais-th-facture-master-2')
      );
      this.searchValue = localStor.searchValue ? localStor.searchValue : null;
      this.statut = localStor.statut ? localStor.statut : [];
      this.isLocalStorageStatut = this.statut;
      this.statut_client = localStor.statut_client
        ? localStor.statut_client
        : [];
      this.isLocalStorageStatutClient = this.statut_client;
      this.acheteur = localStor.acheteur ? localStor.acheteur : [];
      this.isLocalStorageAcheteur = this.acheteur;
      this.vendeur = localStor.vendeur ? localStor.vendeur  :[];
      this.isLocalStorageVendeur = this.vendeur;
    } else {
      this.setLocalStorageFactureMaster2();
    }
    this.getSettingFilialeTh({ type: 'master filiale' });

    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.master-filiale-new-interface {
  padding-top: 20px;
  padding-left: 15px;
  width: 99%;
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 75px);
    width: 100%;
    .table-factures-mensuel-style {
      margin-bottom: 0px;
    }
  }
}
</style>
<style lang="scss">
.table-rapport-style .table-factures-mensuel-style {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
</style>
